import React from "react"
import { Link } from "gatsby"

import Layout from '../components/layout'
import ContentContain from '../components/contentContain'

const ContactPage = () => {
	return (
		<Layout>
			<ContentContain>
				<h1>Contact Me</h1>
				<h2>I would love to hear from you</h2>
				<Link to="">Lets go home</Link>
			</ContentContain>
		</Layout>
	)
}

export default ContactPage
